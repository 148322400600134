import { Language, PAGE_SIZE_EVENTS, SORT_BY_UPDATE_KEY } from '../../../constants';
import {
  EducationActivityTargetAudience,
  EllohaEvent,
  EventCategoryDTO,
  EventDescriptionDTO,
  EventDetails,
  EventGeneralInfoDTO,
  EventInstitutionDTO,
  EventIsPartOfDTO,
  EventLinkDTO,
  EventPriceDTO,
  EventSchemaDTO,
  EventTargetAudience,
  PageEvent,
  UnmatchedEllohaEvent,
} from '../../../types/eventTypes';
import { MediaDTO } from '../../../types/generalTypes';
import { InstitutionUserLinkDTO } from '../../../types/userTypes';
import { mutation, query } from '../headers';
import { EventSearchParams, UserVenuesParams } from '../model';
import request from '../request';
import { QuickTagDTO } from '../../../types/tagTypes';

const eventFetch = {
  search: async ({
    page = 0,
    size = PAGE_SIZE_EVENTS,
    sort = `${SORT_BY_UPDATE_KEY},desc`,
    data,
  }: EventSearchParams): Promise<PageEvent> =>
    request({
      path: `/event/search?size=${size}&page=${page}&sort=${sort}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  indexUser: async ({
    page = 0,
    size = PAGE_SIZE_EVENTS,
    id,
    sort = `${SORT_BY_UPDATE_KEY},desc`,
  }: UserVenuesParams): Promise<PageEvent> =>
    request({
      path: `/event/user/${id}?size=${size}&page=${page}&sort=${sort}`,
      args: {
        headers: query(),
      },
    }),
  show: async (id: number) =>
    request({
      path: `/event/${id}`,
      args: {
        headers: query(),
      },
    }),
  // TODO: REFACTOR BELOW WITH VENUES
  create: async (data: any) =>
    request({
      path: '/event/new',
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  // TODO: REFACTOR BELOW WITH VENUES
  archive: async (id: number) =>
    request({
      path: `/event/${id}/archive`,
      args: {
        method: 'PATCH',
        headers: query(),
      },
    }),
  // TODO: REFACTOR BELOW WITH VENUES
  unarchive: async (id: number) =>
    request({
      path: `/event/${id}/unarchive`,
      args: {
        method: 'PATCH',
        headers: query(),
      },
    }),
  autovalidate: async (id: number) =>
    request({
      path: `/event/${id}/autovalidate`,
      args: {
        method: 'PATCH',
        headers: query(),
      },
    }),
  publish: async (id: number) =>
    request({
      path: `/event/${id}/publish`,
      args: {
        method: 'PATCH',
        headers: query(),
      },
    }),
  unpublish: async (id: number) =>
    request({
      path: `/event/${id}/unpublish`,
      args: {
        method: 'PATCH',
        headers: query(),
      },
    }),
  requestPublish: async (id: number) =>
    request({
      path: `/event/${id}/askForPublish`,
      args: {
        method: 'PATCH',
        headers: query(),
      },
    }),
  getAllEventCategories: async (lang: Language = 'fr'): Promise<EventCategoryDTO[]> =>
    request({
      path: `/event/category?lang=${lang}`,
      args: {
        headers: query(),
      },
    }),
  // getSelectedCategories: async (
  //   id: number,
  //   lang: Language = 'fr',
  // ): Promise<EventCategoryDTO[]> =>
  //   request({
  //     path: `/event/category/${id}?lang=${lang}`,
  //     args: {
  //       headers: {
  //         ...authHeader(),
  //       },
  //     },
  //   }),
  updateGeneralInfo: async (data: EventGeneralInfoDTO) =>
    request({
      path: '/event/generalInfo',
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  updateDescription: async (data: EventDescriptionDTO) =>
    request({
      path: '/event/description',
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  updateOpeningHours: async (data: EventSchemaDTO, id: number) =>
    request({
      // path: '/event/openingHours',
      path: `/event/schema/${id}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  convertWeekToUnique: async (data: { id: number }) =>
    request({
      path: '/event/schema/convert',
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  updateMedia: async ({ data, id }: { data: MediaDTO[]; id: number }) =>
    request({
      path: `/event/media/${id}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  updatePricing: async (data: EventPriceDTO) =>
    request({
      path: '/event/price',
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  linkEvent: async (data: EventLinkDTO) =>
    request({
      path: '/event/festival/link',
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  unlinkEvent: async (data: EventLinkDTO) =>
    request({
      path: '/event/festival/unlink',
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  searchLinkedEvent: async ({
    id,
    name,
    lang,
  }: {
    id: number;
    name: string;
    lang: Language;
  }) =>
    request({
      path: `/event/festival/${id}/search?name=${name}&lang=${lang}&limit=100`,
      args: {
        headers: query(),
      },
    }),
  importedEvents: {
    lock: async (id: number): Promise<EventDetails> =>
      request({
        path: `/event/${id}/lock`,
        args: {
          method: 'PATCH',
          headers: query(),
        },
      }),
    unlock: async (id: number): Promise<EventDetails> =>
      request({
        path: `/event/${id}/unlock`,
        args: {
          method: 'PATCH',
          headers: query(),
        },
      }),
  },
  searchPlace: async ({
    name,
    lang,
    region,
    onlyMiceVenues,
    includeImportedVenues,
  }: {
    name: string;
    lang: Language;
    region: string;
    onlyMiceVenues: boolean;
    includeImportedVenues: boolean;
  }): Promise<InstitutionUserLinkDTO[]> =>
    request({
      path: `/userLink/venues/search?name=${name}&lang=${lang}&onlyMiceVenues=${onlyMiceVenues}&region=${region}&includeImportedVenues=${includeImportedVenues}&limit=100`,
      args: {
        headers: query(),
      },
    }),
  searchOrganizerBasic: async (): Promise<InstitutionUserLinkDTO[]> =>
    request({
      path: '/userLink/venues',
      args: {
        headers: query(),
      },
    }),
  fetchDefaultOrganizerPlace: async (userId: number): Promise<EventInstitutionDTO> =>
    request({
      path: `/userLink/${userId}/defaultVenue`,
      args: {
        headers: query(),
      },
    }),
  fetchTargetAudiences: async (): Promise<EventTargetAudience[]> =>
    request({
      path: '/event/targetAudience',
      args: {
        headers: query(),
      },
    }),
  fetchEducationalTargetAudiences: async (): Promise<EducationActivityTargetAudience[]> =>
    request({
      path: '/educationalTargetAudience',
      args: {
        headers: query(),
      },
    }),
  // TODO: UPDATE TYPE BELOW WHEN READY
  updateAudience: async (data: any, id: number) =>
    request({
      path: `/event/audience/${id}`,
      args: {
        method: 'POST',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  fetchQuickTags: async (id: number): Promise<QuickTagDTO[]> =>
    request({
      path: `/event/${id}/quicktags`,
      args: {
        method: 'GET',
        headers: mutation(),
      },
    }),
  updateQuickTags: async (data: any, id: number): Promise<QuickTagDTO[]> =>
    request({
      path: `/event/${id}/quicktags`,
      args: {
        method: 'PATCH',
        headers: mutation(),
        body: JSON.stringify(data),
      },
    }),
  updateStatus: async (id: number) =>
    request({
      path: `/event/${id}/status`,
      args: {
        method: 'PATCH',
        headers: query(),
      },
    }),
  getProgram: async (eventId: number) =>
    request({
      path: `/event/program/${eventId}`,
      args: {
        method: 'GET',
        headers: query(),
      },
    }),
  getIsPartOfOptions: async (): Promise<EventIsPartOfDTO[]> =>
    request({
      path: '/isPartOf',
      args: {
        method: 'GET',
        headers: query(),
      },
    }),
  getEllohaEvents: async (id?: number): Promise<EllohaEvent[]> =>
    request({
      path: id ? `/event/${id}/ellohaEvents` : '/ellohaEvents',
      args: {
        method: 'GET',
        headers: query(),
      },
    }),
  getUnmatchedEllohaEvents: async (): Promise<UnmatchedEllohaEvent[]> =>
    request({
      path: '/ellohaEvents/unmatched',
      args: {
        method: 'GET',
        headers: query(),
      },
    }),
};

export default eventFetch;
